// This file is generated automatically
// Don't edit this file directly

import { call, toParams } from './utils'

export let api_origin = '/api'

// GET /api/scene/random
export function getRandomScene(input: GetRandomSceneInput): Promise<GetRandomSceneOutput & { error?: string }> {
  return call('GET', api_origin + `/scene/random`)
}
export type GetRandomSceneInput = {
}
export type GetRandomSceneOutput = {
  scene_id: number
  image_url: string
  music_url: null | string
  has_bookmarked: boolean
}

// GET /api/scene/latest
export function getLatestScene(input: GetLatestSceneInput): Promise<GetLatestSceneOutput & { error?: string }> {
  return call('GET', api_origin + `/scene/latest`)
}
export type GetLatestSceneInput = {
}
export type GetLatestSceneOutput = {
  image_url: string
  music_url: null | string
  id: number
  prompt: string
  submit_time: number
}

// GET /api/scene/latests
export function getLatestScenes(input: GetLatestScenesInput): Promise<GetLatestScenesOutput & { error?: string }> {
  return call('GET', api_origin + `/scene/latests`)
}
export type GetLatestScenesInput = {
}
export type GetLatestScenesOutput = {
  scenes: Array<{
    id: number
    image_prompt: string
    music_prompt: null | string
    image_url: string
    music_url: null | string
    image_style: string
    music_style: null | string
    submit_time: number
    has_bookmarked: boolean
  }>
}

// GET /api/scene/bookmarks
export function getBookmarkedScenes(input: GetBookmarkedScenesInput): Promise<GetBookmarkedScenesOutput & { error?: string }> {
  return call('GET', api_origin + `/scene/bookmarks`)
}
export type GetBookmarkedScenesInput = {
}
export type GetBookmarkedScenesOutput = {
  scenes: Array<{
    id: number
    image_prompt: string
    music_prompt: null | string
    image_url: string
    music_url: null | string
    image_style: string
    music_style: null | string
    submit_time: number
    has_bookmarked: boolean
  }>
}

// GET /api/scene/:id
export function getSceneById(input: GetSceneByIdInput): Promise<GetSceneByIdOutput & { error?: string }> {
  let { params } = input
  return call('GET', api_origin + `/scene/${params.id}`)
}
export type GetSceneByIdInput = {
  params: {
    id: number
  }
}
export type GetSceneByIdOutput = {
  id: number
  image_prompt: string
  music_prompt: null | string
  image_url: string
  music_url: null | string
  image_style: string
  music_style: null | string
  submit_time: number
  has_bookmarked: boolean
}

// POST /api/scene/:id/share
export function shareScene(input: ShareSceneInput): Promise<ShareSceneOutput & { error?: string }> {
  let { params } = input
  return call('POST', api_origin + `/scene/${params.id}/share`)
}
export type ShareSceneInput = {
  params: {
    id: number
  }
}
export type ShareSceneOutput = {
}

// POST /api/scene/:id/download
export function downloadScene(input: DownloadSceneInput): Promise<DownloadSceneOutput & { error?: string }> {
  let { params } = input
  return call('POST', api_origin + `/scene/${params.id}/download`)
}
export type DownloadSceneInput = {
  params: {
    id: number
  }
}
export type DownloadSceneOutput = {
}

// POST /api/scene/search
export function searchScene(input: SearchSceneInput): Promise<SearchSceneOutput & { error?: string }> {
  return call('POST', api_origin + `/scene/search`, input.body)
}
export type SearchSceneInput = {
  body: {
    query: string
    image: boolean
    styleEnabled: boolean
    style: string
  }
}
export type SearchSceneOutput = {
  scenes: Array<{
    id: number
    image_prompt: string
    music_prompt: null | string
    image_url: string
    music_url: null | string
    image_style: string
    music_style: null | string
    submit_time: number
    has_bookmarked: boolean
  }>
}

// POST /api/scene/generate/by-guest
export function generateSceneByGuest(input: GenerateSceneByGuestInput): Promise<GenerateSceneByGuestOutput & { error?: string }> {
  return call('POST', api_origin + `/scene/generate/by-guest`, input.body)
}
export type GenerateSceneByGuestInput = {
  body: {
    image?: {
      prompt: string
      style: string
    }
    music?: {
      prompt: string
      style: string
    }
    edit_scene_id?: number
  }
}
export type GenerateSceneByGuestOutput = {
  next_time: number
  scene: null | ({
    scene_id: number
    image_url: string
    music_url: null | string
  })
}

// POST /api/scene/generate/by-canva
export function generateSceneByCanva(input: GenerateSceneByCanvaInput): Promise<GenerateSceneByCanvaOutput & { error?: string }> {
  return call('POST', api_origin + `/scene/generate/by-canva`, input.body)
}
export type GenerateSceneByCanvaInput = {
  body: {
    image?: {
      prompt: string
      style: string
    }
    music?: {
      prompt: string
      style: string
    }
    edit_scene_id?: number
  }
}
export type GenerateSceneByCanvaOutput = {
  next_time: number
  scene: null | ({
    scene_id: number
    image_url: string
    music_url: null | string
  })
}

// POST /api/scene/generate/by-user
export function generateSceneByUser(input: GenerateSceneByUserInput): Promise<GenerateSceneByUserOutput & { error?: string }> {
  return call('POST', api_origin + `/scene/generate/by-user`, input.body)
}
export type GenerateSceneByUserInput = {
  body: {
    image?: {
      prompt: string
      style: string
    }
    music?: {
      prompt: string
      style: string
    }
    edit_scene_id?: number
  }
}
export type GenerateSceneByUserOutput = {
  next_time: number
  scene: null | ({
    scene_id: number
    image_url: string
    music_url: null | string
  })
}

// GET /api/scene/:scene_id/music
export function waitSceneMusic(input: WaitSceneMusicInput): Promise<WaitSceneMusicOutput & { error?: string }> {
  let { params } = input
  return call('GET', api_origin + `/scene/${params.scene_id}/music`)
}
export type WaitSceneMusicInput = {
  params: {
    scene_id: number
  }
}
export type WaitSceneMusicOutput = {
  music_url: null | string
}

// POST /api/scene/:scene_id/bookmark
export function bookmarkScene(input: BookmarkSceneInput): Promise<BookmarkSceneOutput & { error?: string }> {
  let { params } = input
  return call('POST', api_origin + `/scene/${params.scene_id}/bookmark`)
}
export type BookmarkSceneInput = {
  params: {
    scene_id: number
  }
}
export type BookmarkSceneOutput = {
}

// DELETE /api/scene/:scene_id/bookmark
export function unBookmarkScene(input: UnBookmarkSceneInput): Promise<UnBookmarkSceneOutput & { error?: string }> {
  let { params } = input
  return call('DELETE', api_origin + `/scene/${params.scene_id}/bookmark`)
}
export type UnBookmarkSceneInput = {
  params: {
    scene_id: number
  }
}
export type UnBookmarkSceneOutput = {
}
