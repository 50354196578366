import * as sdk from '../sdk/scene'
import { showNotification } from './notification'

export async function downloadFile(input: { url: string }) {
  let res = await fetch(input.url)
  let blob = await res.blob()
  let url = URL.createObjectURL(blob)
  let filename = input.url.split('/').pop()!.split('?')[0]
  let a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
  URL.revokeObjectURL(url)
}

export async function downloadScene(input: {
  scene_id: number
  image_url: string
}) {
  // track download count
  sdk.downloadScene({ params: { id: input.scene_id } })

  try {
    downloadFile({
      url: input.image_url,
    })
  } catch (error) {
    showNotification(String(error))
  }
}
