export function showNotification(message: string) {
  const notification = document.createElement('div')
  notification.classList.add('notification')
  notification.textContent = message
  document.body.appendChild(notification)

  setTimeout(() => {
    notification.remove()
  }, 3000)
}
