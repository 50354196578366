import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
//@ts-ignore
import { Pannellum } from 'pannellum-react'
import {
  getSceneById,
  generateSceneByGuest,
  bookmarkScene,
  unBookmarkScene,
} from '../sdk/scene'
//import Feelings_image from './Feelings_HKSTP.jpg';
import './Editor.css'
import { getSkyboxStyleList } from '../sdk/skybox'
import { useGet } from '../hooks/useGet'
import { VolumeSlider } from '../components/Music'
import { langOptions, translate, useLang } from '../components/Locale'
import { downloadScene } from '../utils/download'
import { shareScene } from '../utils/share'

function Editor() {
  const params = useParams<{ scene_id: string }>()
  const scene_id: number | null = +params.scene_id!

  const navigate = useNavigate()

  const [showImage, setShowImage] = useState(false)
  const [showMusic, setShowMusic] = useState(false)
  const [imagePrompt, setImagePrompt] = useState('')
  const [musicPrompt, setMusicPrompt] = useState('')
  const [imageStyleText, setImageStyleText] = useState('Fantasy')
  const [musicStyleText, setMusicStyleText] = useState('')
  const [imageModel, setImageModel] = useState('Skybox')
  const [musicModel, setMusicModel] = useState('Suno')
  const [searchTerm, setSearchTerm] = useState('')

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false)
  const { lang, setLang } = useLang()

  const [imageUrl, setImageUrl] = useState('')
  const [musicUrl, setMusicUrl] = useState('')
  const [hasBookmarked, setHasBookmarked] = useState(false)

  const [isDownloading, setIsDownloading] = useState(false)

  const skyboxStyleList = useGet([], () =>
    getSkyboxStyleList({}).then(json => json.styles),
  ).state

  const handleSubmission = () => {
    generateSceneByGuest({
      body: {
        image: showImage
          ? {
              prompt: imagePrompt,
              style: imageStyleText,
            }
          : undefined,
        music: showMusic
          ? {
              prompt: musicPrompt,
              style: musicStyleText,
            }
          : undefined,
        edit_scene_id: scene_id,
      },
    }).then(response => {
      if (response.error) {
        console.log(response.error)
      } else {
        navigate(`/app/editor/${response.scene?.scene_id}`)
      }
    })
  }

  const handleSearch = () => {
    navigate(`/app/editor/${searchTerm}`)
  }

  const toggleEditorDropdown = () => {
    setDropdownOpen(!isDropdownOpen)
  }

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!isLanguageDropdownOpen)
  }

  useEffect(() => {
    if (!scene_id) return
    getSceneById({ params: { id: scene_id } }).then(json => {
      setImageUrl(json.image_url)
      setMusicUrl(json.music_url || '')
      setHasBookmarked(json.has_bookmarked)
    })
  }, [scene_id])

  useLayoutEffect(() => {
    const onClick = (event: MouseEvent) => {
      console.log('mouse down', event.target)
      let target = event.target as HTMLElement
      if (
        target.closest('.optionsButton') ||
        target.closest('.editor-dropdown') ||
        target.closest('.nav-stop-button') ||
        target.closest('.language-button')
      ) {
        return
      }
      setDropdownOpen(false)
    }
    document.addEventListener('click', onClick)
    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [])

  useLayoutEffect(() => {
    const onClick = (event: MouseEvent) => {
      console.log('mouse down', event.target)
      let target = event.target as HTMLElement
      if (
        target.closest('.language-button') ||
        target.closest('.nav-stop-button')
      ) {
        return
      }
      setLanguageDropdownOpen(false)
    }
    document.addEventListener('click', onClick)
    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [])

  const handleBookmarkScene = async (scene_id: number) => {
    await bookmarkScene({ params: { scene_id } })
    setHasBookmarked(true)
  }

  const handleUnBookmarkScene = async (scene_id: number) => {
    await unBookmarkScene({ params: { scene_id } })
    setHasBookmarked(false)
  }

  const handleDownload = async () => {
    setIsDownloading(true)
    await downloadScene({
      scene_id: scene_id,
      image_url: imageUrl,
    })
    setIsDownloading(false)
  }

  return (
    <div className="editor">
      <Pannellum
        width="100%"
        image={imageUrl}
        pitch={10}
        yaw={300}
        hfov={110}
        autoLoad
        showZoomCtrl={false}
        showFullscreenCtrl={false}
        showControls={false}
      />
      <div className="floating-menu">
        <div className="outer-container">
          <VolumeSlider
            containerClassName="volume-container"
            inputClassName="music-slider"
          />
          <div className="button-container">
            <div className="lang-container">
              <button
                className="language-button action-button"
                onClick={toggleLanguageDropdown}
              >
                <i
                  style={{ marginRight: '3px' }}
                  className="fa-solid fa-globe"
                ></i>
                {translate(lang, {
                  en: 'Language',
                  zh_cn: '语言',
                  zh_hk: '語言',
                })}
              </button>
              {isLanguageDropdownOpen && (
                <div
                  className="language-dropdown"
                  style={{ position: 'absolute', left: '0' }}
                >
                  {langOptions.map(option => (
                    <button
                      className="action-button"
                      onClick={() => setLang(option.lang)}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <button
              className="optionsButton action-button"
              onClick={toggleEditorDropdown}
            >
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>
          {isDropdownOpen && (
            <div className="editor-dropdown">
              {!scene_id ? null : (
                <div className="iconContainer">
                  {hasBookmarked ? (
                    <button
                      onClick={() => handleUnBookmarkScene(scene_id)}
                      title={translate(lang, {
                        en: 'un-bookmark the scene',
                        zh_cn: '取消此场景的书签',
                        zh_hk: '取消呢個場景嘅書籤',
                      })}
                    >
                      <i className="fa-solid fa-bookmark"></i>
                    </button>
                  ) : (
                    <button
                      onClick={() => handleBookmarkScene(scene_id)}
                      title={translate(lang, {
                        en: 'bookmark the scene',
                        zh_cn: '将此场景添加为书签',
                        zh_hk: '將呢個場景加入書籤',
                      })}
                    >
                      <i className="fa-regular fa-bookmark"></i>
                    </button>
                  )}
                  <button
                    onClick={() => shareScene({ scene_id, lang })}
                    title={translate(lang, {
                      en: 'share scene',
                      zh_cn: '分享这个场景',
                      zh_hk: '分享呢個場景',
                    })}
                  >
                    <i className="fa-solid fa-share"></i>
                  </button>
                  <button
                    onClick={handleDownload}
                    title={translate(lang, {
                      en: 'download scene',
                      zh_cn: '下載這個場景',
                      zh_hk: '下載呢個場景',
                    })}
                  >
                    <i className="fa-solid fa-download"></i>
                  </button>
                </div>
              )}
              <div className="SearchByID">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  placeholder={translate(lang, {
                    en: 'Search by ID',
                    zh_cn: '按ID搜索',
                    zh_hk: '按ID搜索',
                  })}
                />
                <button className="action-button" onClick={handleSearch}>
                  {translate(lang, {
                    en: 'Search',
                    zh_cn: '搜索',
                    zh_hk: '搜索',
                  })}
                </button>
              </div>
              <label
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <input
                  type="checkbox"
                  checked={showImage}
                  onChange={() => setShowImage(!showImage)}
                />
                {translate(lang, {
                  en: 'Enable Image',
                  zh_cn: '启用图片',
                  zh_hk: '啟用圖片',
                })}
              </label>
              {showImage && (
                <div>
                  <div className="image">
                    <input
                      className="input-box"
                      value={imagePrompt}
                      onChange={e => setImagePrompt(e.target.value)}
                      placeholder={translate(lang, {
                        en: 'Image Prompt',
                        zh_cn: '图片提示',
                        zh_hk: '圖片提示',
                      })}
                    />
                    <select
                      className="input-box"
                      value={imageStyleText}
                      onChange={e => setImageStyleText(e.target.value)}
                    >
                      {skyboxStyleList.map(style => (
                        <option value={style.name}>{style.name}</option>
                      ))}
                    </select>
                  </div>

                  <select
                    value={imageModel}
                    onChange={e => setImageModel(e.target.value)}
                  >
                    <option value="Skybox">Skybox</option>
                  </select>
                </div>
              )}
              <label
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <input
                  type="checkbox"
                  checked={showMusic}
                  onChange={() => setShowMusic(!showMusic)}
                />
                {translate(lang, {
                  en: 'Enable Music',
                  zh_cn: '启用音乐',
                  zh_hk: '啟用音樂',
                })}
              </label>
              {showMusic && (
                <div>
                  <div className="music">
                    <input
                      className="input-box"
                      value={musicPrompt}
                      onChange={e => setMusicPrompt(e.target.value)}
                      placeholder={translate(lang, {
                        en: 'Music Prompt',
                        zh_cn: '音乐提示',
                        zh_hk: '音樂提示',
                      })}
                    />
                    <input
                      className="input-box"
                      value={musicStyleText}
                      onChange={e => setMusicStyleText(e.target.value)}
                      placeholder={translate(lang, {
                        en: 'Music Style',
                        zh_cn: '音乐风格',
                        zh_hk: '音樂風格',
                      })}
                    />
                  </div>

                  <div className="ModelOption">
                    <select
                      value={musicModel}
                      onChange={e => setMusicModel(e.target.value)}
                    >
                      <option value="Model A">Suno</option>
                    </select>

                    <button
                      className="action-button"
                      onClick={handleSubmission}
                    >
                      {translate(lang, {
                        en: 'Submit',
                        zh_cn: '提交',
                        zh_hk: '提交',
                      })}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Editor
