import React from 'react'
import { useStateProxy } from 'use-state-proxy'
import { useStorageState } from 'react-use-storage-state'

export type LocaleVariant = {
  en: string
  zh_cn: string
  zh_hk: string
}

export type Lang = 'en' | 'zh_cn' | 'zh_hk'

export const langOptions: { lang: Lang; label: string }[] = [
  { lang: 'en', label: 'English' },
  { lang: 'zh_cn', label: '简体中文' },
  { lang: 'zh_hk', label: '繁体中文' },
]

function getDefaultLang(): Lang {
  let lang = navigator.language.toLowerCase()
  if (lang.includes('zh') && lang.includes('hk')) return 'zh_hk'
  if (lang.includes('zh') && lang.includes('tw')) return 'zh_hk'
  if (lang.includes('zh')) return 'zh_cn'
  return 'en'
}

export function Locale(props: LocaleVariant) {
  const { lang } = useLang()
  return <>{translate(lang, props)}</>
}

export function useLang() {
  const [lang, setLang] = useStorageState<Lang>('lang', getDefaultLang())
  return { lang, setLang }
}

export function getLang(): Lang {
  let value = localStorage.getItem('lang')
  try {
    value = JSON.parse(value || '""')
  } catch (error) {
    // ignore invalid value
  }
  return (value as Lang) || getDefaultLang()
}

export function useLocale(props: LocaleVariant) {
  const lang = useLang()
  return <Locale {...props} />
}

export function translate(lang: Lang, variant: LocaleVariant) {
  return variant[lang]
}
