import { Lang, translate } from '../components/Locale'
import { showNotification } from './notification'
import { copyToClipboard as copyToClipboardLib } from '@beenotung/tslib/dom'

export async function copyToClipboard(input: {
  url: string
  lang: Lang
  successMessage: string
}) {
  try {
    await copyToClipboardLib(input.url)
  } catch (error) {
    let message = translate(input.lang, {
      en: 'Clipboard copy not supported in your browser',
      zh_cn: '您的浏览器不支持剪贴板复制',
      zh_hk: '您的瀏覽器不支持剪貼板複製',
    })
    throw new Error(message)
  }
  showNotification(input.successMessage)
}
