import { Lang, translate } from '../components/Locale'
import * as sdk from '../sdk/scene'
import { copyToClipboard } from './clipboard'

export async function shareUrl(input: { url: string; lang: Lang }) {
  let successMessage = translate(input.lang, {
    en: 'Share link copied to clipboard',
    zh_cn: '分享链接已复制到剪贴板',
    zh_hk: '分享鏈接已複製到剪貼板',
  })
  await copyToClipboard({
    url: input.url,
    lang: input.lang,
    successMessage,
  })
}

export async function shareScene(input: { scene_id: number; lang: Lang }) {
  let { scene_id, lang } = input

  // track share count
  sdk.shareScene({ params: { id: scene_id } })

  let url = `${window.location.origin}/app/editor/${scene_id}`
  let title = translate(lang, {
    en: 'Share Scene',
    zh_cn: '分享场景',
    zh_hk: '分享場景',
  })
  let text = translate(lang, {
    en: 'Check out this scene:',
    zh_cn: '看看这个场景:',
    zh_hk: '看看這個場景:',
  })

  try {
    await navigator.share({
      title,
      text,
      url,
    })
  } catch (error) {
    let message = String(error)
    if (message.toLowerCase().includes('cancellation')) {
      return
    }
    await shareUrl({ url, lang })
  }
}
