import React, { useState, useEffect, ChangeEvent, MouseEvent } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Gallery.css'
import {
  GetLatestScenesOutput,
  getLatestScenes,
  getBookmarkedScenes,
  searchScene,
} from '../sdk/scene'
import { Locale, translate, useLang } from '../components/Locale'
import { useToken } from '../hooks/useToken'

type SceneResult = GetLatestScenesOutput['scenes'][number]

type Mode = 'all' | 'bookmarks'

// TODO allow sort all by latest, most bookmarked, most shared, most downloaded
// TODO do pagination with API, not just on client side

// TODO: sort bookmarks by bookmark time

export default function Gallery() {
  const { lang } = useLang()

  const [mode, setMode] = useState<Mode>('all')

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<SceneResult[]>([])
  const [filters, setFilters] = useState({
    style: '',
    date: 'Any',
    hasImage: true,
    hasMusic: false,
    styleEnabled: false,
  })
  const [searchPerformed, setSearchPerformed] = useState(false)
  const [musicIcon, setMusicIcon] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(10)
  const { token } = useToken()
  const navigate = useNavigate()

  useEffect(() => {
    async function loadList() {
      if (mode === 'all' && !searchQuery) {
        getLatestScenes({})
          .then(response => {
            if (response.error) {
              throw new Error(response.error)
            }
            setSearchResults(response.scenes)
          })
          .catch(error => {
            console.error('Failed to fetch scenes:', error)
            setSearchResults([]) // Clear results or handle error appropriately
          })
        return
      }
      if (mode === 'all' && searchQuery) {
        // TODO really need to set the state?

        setSearchPerformed(true)

        if (filters.hasMusic) {
          setMusicIcon(true)
        } else {
          setMusicIcon(false)
        }

        searchScene({
          body: {
            query: searchQuery,
            image: filters.hasImage,
            styleEnabled: filters.styleEnabled,
            style: filters.style,
          },
        })
          .then(response => {
            if (response.error) {
              throw new Error(response.error)
            }
            setSearchResults(response.scenes)
          })
          .catch(error => {
            console.error('Failed to search scenes:', error)
            setSearchResults([])
          })
        return
      }
      if (mode === 'bookmarks') {
        getBookmarkedScenes({})
          .then(response => {
            if (response.error) {
              throw new Error(response.error)
            }
            setSearchResults(response.scenes)
          })
          .catch(error => {
            console.error('Failed to fetch scenes:', error)
            setSearchResults([])
          })
        return
      }
    }
    loadList()
  }, [mode, searchQuery])

  useEffect(() => {}, [])

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    if (name === 'styleEnabled') {
      setFilters({
        ...filters,
        styleEnabled: checked,
        style: checked ? filters.style : '', // Clear style if checkbox is unchecked
      })
    } else {
      if (name === 'hasImage' || name === 'hasMusic') {
        setFilters(prevFilters => ({
          ...prevFilters,
          hasImage: name === 'hasImage' ? checked : !checked,
          hasMusic: name === 'hasMusic' ? checked : !checked,
        }))
      }
    }
  }

  const performSearch = (event: MouseEvent<HTMLButtonElement>) => {}

  const handleResultsPerPageChange = (newResultsPerPage: number) => {
    const newTotalPages = Math.ceil(searchResults.length / newResultsPerPage)
    const newCurrentPage = Math.min(currentPage, newTotalPages) // Adjust current page if it exceeds new total pages
    setResultsPerPage(newResultsPerPage)
    setCurrentPage(newCurrentPage)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const totalPages = Math.ceil(searchResults.length / resultsPerPage)
  const visibleResults = searchResults.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage,
  )

  console.log({
    searchResults,
    visibleResults,
  })

  // const totalBookmarkPages = Math.ceil(bookmarks.length / resultsPerPage)
  // const visibleBookmarks = bookmarks.slice(
  //   (currentPage - 1) * resultsPerPage,
  //   currentPage * resultsPerPage,
  // )

  // console.log('Bookmarks')
  // console.log(visibleBookmarks)

  return (
    <>
      <div className="search-bar">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={translate(lang, {
            en: 'Search scenes...',
            zh_cn: '搜索场景...',
            zh_hk: '搜索場景...',
          })}
        />
        <button onClick={performSearch}>
          <Locale en="Search" zh_cn="搜索" zh_hk="搜索" />
        </button>
      </div>
      <div className="filters">
        <label>
          <Locale en="Image" zh_cn="图像" zh_hk="圖像" />:
          <input
            type="checkbox"
            name="hasImage"
            checked={filters.hasImage}
            onChange={handleCheckboxChange}
          />
        </label>
        <label>
          <Locale en="Music" zh_cn="音乐" zh_hk="音樂" />:
          <input
            type="checkbox"
            name="hasMusic"
            checked={filters.hasMusic}
            onChange={handleCheckboxChange}
          />
        </label>
        <label>
          <Locale en="Enable Style" zh_cn="启用风格" zh_hk="啟用風格" />:
          <input
            type="checkbox"
            checked={filters.styleEnabled}
            onChange={handleCheckboxChange}
            name="styleEnabled"
          />
        </label>
        <label>
          <Locale en="Style" zh_cn="风格" zh_hk="風格" />:
          <input
            type="text"
            value={filters.style}
            onChange={handleInputChange}
            disabled={!filters.styleEnabled}
            name="style"
          />
        </label>
      </div>
      <div className="buttons-header">
        <button
          onClick={() => {
            if (!token) {
              alert('Please login to view bookmarks')
              navigate('/app/login')
              return
            }
            setMode('bookmarks')
          }}
          className={mode === 'bookmarks' ? 'active' : ''}
        >
          Bookmarks
        </button>

        <button
          onClick={() => setMode('all')}
          className={mode === 'all' ? 'active' : ''}
          style={{ borderLeft: '2px solid blue', paddingLeft: '1.2rem' }}
        >
          Gallery
        </button>
      </div>
      <div className="card-container">
        {searchResults.length === 0 && mode === 'bookmarks' ? (
          <p>
            <Locale
              en="No bookmarks yet"
              zh_cn="还没有书签"
              zh_hk="還沒有書籤"
            />
          </p>
        ) : null}
        {visibleResults.map(result => (
          <Link
            key={result.id}
            className="scene-card"
            to={`/app/editor/${result.id}`}
          >
            {musicIcon || !result.image_url ? (
              <img
                src="https://img.icons8.com/ios/452/music--v1.png"
                alt="music icon"
                className="music-icon"
              />
            ) : (
              <img
                src={result.image_url}
                alt={result.image_prompt}
                className="scene-image"
              />
            )}
            <div className="scene-info">
              <h2
                className="prompt-text"
                title={translate(lang, {
                  en: 'Image prompt text',
                  zh_cn: '图像提示文本',
                  zh_hk: '圖像提示文本',
                })}
              >
                <i className="fa-solid fa-image"></i> {result.image_prompt}
              </h2>
              <h3
                className="style-name"
                title={translate(lang, {
                  en: 'Image style name',
                  zh_cn: '图像风格名称',
                  zh_hk: '圖像風格名稱',
                })}
              >
                <i className="fa-solid fa-palette"></i> {result.image_style}
              </h3>
              <h2
                hidden={!result.music_prompt}
                className="prompt-text"
                title={translate(lang, {
                  en: 'Music prompt text',
                  zh_cn: '音乐提示文本',
                  zh_hk: '音樂提示文本',
                })}
              >
                <i className="fa-solid fa-music"></i> {result.music_prompt}
              </h2>
              <h3
                hidden={!result.music_style}
                className="style-name"
                title={translate(lang, {
                  en: 'Music style name',
                  zh_cn: '音乐风格名称',
                  zh_hk: '音樂風格名稱',
                })}
              >
                <i className="fa-solid fa-record-vinyl"></i>{' '}
                {result.music_style}
              </h3>
            </div>
          </Link>
        ))}
      </div>
      <div className="pagination-controls">
        <div className="results-per-page">
          <span>Results per page:</span>
          <select
            className="page-selector-dropdown"
            value={resultsPerPage}
            onChange={e =>
              handleResultsPerPageChange(parseInt(e.target.value, 10))
            }
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        <div className="pagination">
          {currentPage > 1 && (
            <>
              <button onClick={() => handlePageChange(1)}>1</button>
              {currentPage > 2 && <button className="ellipsis">...</button>}
            </>
          )}
          {currentPage > 2 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>
              {currentPage - 1}
            </button>
          )}
          <button className="current">{currentPage}</button>
          {currentPage < totalPages - 1 && (
            <button onClick={() => handlePageChange(currentPage + 1)}>
              {currentPage + 1}
            </button>
          )}
          {currentPage < totalPages - 2 && (
            <button className="ellipsis">...</button>
          )}
          {currentPage < totalPages && (
            <>
              {/* {currentPage !== totalPages - 1 && (
                <button className="ellipsis">...</button>
              )} */}
              {currentPage !== totalPages && (
                <button onClick={() => handlePageChange(totalPages)}>
                  {totalPages}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
